.testimonial-image-container {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.testimonial-image {
    min-width: 140px;
    min-height: 140px;
    max-height: 260px;
    max-width: 260px;
    width: 100%;
    height: auto;
    /*padding-top: 100%;*/
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.testimonial-text {
    position: relative;
    display: inline-block;
    padding: 2%;
    width: 100%;
    vertical-align: middle;
    font-style: italic;
}

.testimonial-name {
    align-self: flex-end;
    margin-right: 2%;
    margin-top: 10px;
}

.align-left {
    align-self: flex-start;
    margin-left: 2%;
    margin-right: 0;
}

.testimonial-container {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.testimonial-text-container {
    display: flex;
    flex-direction: column;
    margin: 25px;
    font-size: 20px;
    width: 80%;
}

@media screen and (max-width: 700px) {
    .testimonial-text-container {
        font-size: 16px;
        margin: 5px 0;
    }

    .testimonial-container > *:first-child {
        margin-right: 50px;
    }

    .align-left {
        margin-right: 50px;
    }

    .testimonial-container {
        padding: 10px;
    }
}

@media screen and (max-width: 499px) {
    .testimonial-text {
        font-size: 14px;
    }
}


@media screen and (max-width: 450px) {
    .testimonial-container > *:first-child {
        margin-right: 80px;
    }
}

