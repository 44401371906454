
.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    background: #191919;
    color: #bcc5d1;
    padding: 30px 2.5% 15px 2.5%;
    margin-bottom: 30px;
    margin-top: 30px;
}

.contact-headers {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 50px;
    line-height: 24px;
    align-items: center;
}

.contact-headers > *:first-child {
    width: 25%;
}

/*.contact-headers > *:first-child:before {*/
/*    font-family: 'FontAwesome';*/
/*    content: "\f0e0";*/
/*}*/

.contact-headers > *:nth-child(2) {
    width: 75%;
}

.lower-contact {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.contact-form,
.contact-labels {
    display: flex;
    flex-direction: column;
}

.contact-labels {
    width: 25%;
    color: #EBEEEE;
}

.contact-form {
    width: 70%;
}

.contact-form > * {
    padding-left: 10px;
    color: #eee;
    background: #373233;
    border: 0;
    outline: none;
    font-size: 15px;
}

.contact-form > textarea {
    padding-top: 10px;
    width: auto;
    resize: vertical;
    font: inherit;
}
.contact-labels > *,
.contact-form > * {
    height: 50px;
    margin-bottom: 20px;
}

.email-link {
    color: #11ABB0;
    text-decoration: none;
    transition: color 0.2s ease;
}

.email-link:hover {
    color: white;
}

.contact-labels > * {
    display: flex;
    align-items: center;
    /*justify-content: center;*/
}

.contact-submit {
    width: 90px;
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
    background: #0D0D0D;
    border: none;
    cursor: pointer;
    display: inline-block;
    border-radius: 3px;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.contact-submit:hover {
    color: #0D0D0D;
    background: #fff;
}
