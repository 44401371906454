.pdf-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 40px;
}

.pdf-page-title {
    font-size: 3vh;
}