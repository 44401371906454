.link {
    --backgroundcolor: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--backgroundcolor);
    text-decoration: none;
    color: rgb(59, 59, 59);
    padding: 10px;
    /*border-bottom: 2px solid var(--background-blue);*/
    box-sizing: border-box;
    vertical-align: middle;
    max-width: 250px;
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 46px;
    text-align: center;
    height: 90%;
}

.link:hover {
    border-bottom: 2px solid black;
    color: black;
}

/*
    Special rule for the WHY WE'RE DIFFERENT link
 */
.link:last-child {
    margin-left: 30px;
    font-size: 30px;
}

.navbar {
    background-color: var(--background-blue);
    position: fixed;
    top: 0;
    left: 0;
    height: 120px;
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100000;
}

.navbar > * {
    background-color: transparent;
}

@media screen and (max-width: 984px) {
    .link {
        text-align: left;
    }
    .link:last-child {
        font-size: 25px;
        margin-left: 0;
    }

}

@media screen and (max-width: 499px) {
    .navbar {
        height: 120px;
        margin-bottom: 20px;
    }
}
