.slider-container {
    align-items: center;
    width: 100%;
}

.large-screen-slider {
    width: 70%;
}

.slider {
    /*width: 60%;*/
    width: 60%;
}

.slider-side-text {
    margin: 10px;
}

.label {
    width: 15%;
    text-align: center;
}

.bottom-slider-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.left-slider-text {
    text-align: center;
}

.bottom-left-text {
    margin-right: 30px;
}

.right-slider-text {
    /*text-align: right;*/
}