.search-div {
    width: 100%;
}

/* Style the search field */
.search-input {
    padding: 10px;
    font-size: 17px;
    border: 1px solid grey;
    float: left;
    width: 80%;
    background: #f1f1f1;
}

/* Style the search button */
.search-button {
    float: left;
    width: 20%;
    padding: 10px;
    background: var(--accent-color);
    color: white;
    font-size: 17px;
    border: 1px solid grey;
    border-left: none; /* Prevent double borders */
    cursor: pointer;
}

.search-button:hover {
    background: #0b7dda;
}

.search-form {
    display: flex;
    flex-direction: row;
}

/* Clear floats */
.search-form::after {
    content: "";
    clear: both;
    display: table;
}
