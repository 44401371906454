.hover-card-container,
.hover-card-container-straight {
    margin-bottom: 40px;
    width: 100%;
}

.hover-card-container > *,
.hover-card-container-straight > * {
    margin: 30px 30px 10px;
}

.row-based-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    height: auto;
}

.side-scroll-container {
    width: 100%;
    max-width: 100vw;
    display: flex;
    justify-content: left;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/*.side-scroll-container::-webkit-scrollbar {*/
/*    display: none*/
/*}*/

/*.side-scroll-container {*/
/*    -ms-overflow-style: none;  !* IE and Edge *!*/
/*    scrollbar-width: none;  !* Firefox *!*/
/*}*/

.show-more-button {
    width: 60%;
    max-width: 400px;
    border: none;
    height: 55px;
    background-color: #C4C4C4;;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
}

.show-more-button:hover {
    cursor: pointer;
    filter: brightness(80%);
}

@media screen and (max-width: 1200px) {
    .hover-card-container-straight > * {
        margin: 15px 15px 10px;
    }
}

@media screen and (max-width: 539px) {
    .hover-card-container > *:nth-child(odd) {
        margin-right: 10px;
        margin-left: 10px;
    }

    .hover-card-container > *:nth-child(even) {
        margin-right: 10px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 499px) {
    .hover-card-container-straight > * {
        margin: 8px 8px 10px;
    }

    .hover-card-container > *:nth-child(odd) {
        margin-right: 5px;
        margin-left: 2px;
    }

    .hover-card-container > *:nth-child(even) {
        margin-right: 0px;
        margin-left: 2px;
    }

    .show-more-button {
        width: 70%;
    }
}
