.extendedhowto-container {
    padding: 40px 50px 15px;
    max-width: 1200px;
}

.extended-howto-section-one,
.extended-howto-section-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.extended-howto-section-one > img,
.extended-howto-section-two > img {
    width: 60%;
    max-height: 100%;
}

.extended-howto-header {
    font-family: Overpass;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 107px;
    margin-bottom: 30px;
    max-width: 1200px;
}

.extended-howto-paragraph {

}

.extended-howto-text {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 31px;
    max-width: 1200px;
}

.extended-howto-section-one {
    height: 500px;
    margin-top: 80px;
    margin-bottom: 50px;
}

.extended-howto-section-one > img {
    height: 100%;
    object-fit: cover;
    margin-right: 50px;
}

.extended-howto-section-one > p {
    max-width: 500px;
}

.extended-howto-section-two {
    height: 640px;
}

.extended-howto-section-two > img {
    height: 100%;
    object-fit: cover;
    margin-left: 50px;
}

.extended-howto-section-two > p {
    max-width: 430px;
}

@media (max-width: 850px) {

    .extended-howto-section-one > img,
    .extended-howto-section-two > img {
        width: 90%;
    }

    .extended-howto-section-two {
        flex-direction: column-reverse;
        height: auto;
    }
    .extended-howto-section-one {
        flex-direction: column;
        height: auto;
        margin-top: 30px;
    }

    .extended-howto-section-one > img {
        height: 380px;
        margin: 0;
    }

    .extended-howto-section-two > img {
        height: 400px;
        margin: 0;
    }

    .extended-howto-header {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 5px;
    }

    .extended-howto-text {
        font-size: 14px;
        line-height: 24px;
    }

    .extended-howto-header,
    .extended-howto-text,
    .extended-howto-section-one > p,
    .extended-howto-section-two > p {
        width: auto;
        padding-left: 5px;
        padding-right: 5px;
    }

    .extended-howto-section-one > p,
    .extended-howto-section-two > p {
        max-width: unset;
        width: 90%;
        margin-top: 15px;
    }

    .extendedhowto-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}
