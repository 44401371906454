.top-shape {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: var(--background-blue);
    clip-path: polygon(0 0, 100% 0, 100% 54%, 68% 84%, 19% 73%, 0 54%);
    height: 100%;
    min-height: 30vh;
    min-width: var(--min-width);
    width: 100%;
}

.homepage-container {
    width: 95%;
}

.homepage-top-container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.homepage-tag-line-div {
    width: auto;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 40px 0 90px 0;
}

.homepage-logo {
    /*min-width: 620px;*/
    width: 30%;
    align-self: center;
    margin-bottom: 30px;
}

.homepage-video {
    width: 60%;
    /*min-width: 710px;*/
    margin-left: 15px;
    height: auto;

}

.homepage-activity-div,
.homepage-location-div,
.homepage-differentiation-div {
    display: flex;
    flex-direction: row;
    height: 900px;
    margin-bottom: 10px;
    align-items: center;
}

.homepage-differentiation-div {
    height: 730px;
    justify-content: center;
    flex-direction: row-reverse;
    margin-bottom: 50px;
}

.homepage-subtitle {
    margin-top: 40px;
    font-family: Open Sans, serif;
    width: 90%;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
}

.homepage-activity-title,
.homepage-location-title {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    height: auto;
    margin-bottom: 10px;
}

.homepage-title-div {
    width: 85%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    font-family: Overpass, sans-serif;
    font-style: normal;
    /*font-weight: bold;*/
    font-size: 40px;
    line-height: 50px;
}

.homepage-activity-text,
.homepage-location-text,
.homepage-differentiation-text {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 34px;
}

.homepage-activity-img-text {
    position: absolute;
    left: 88px;
    top: 36px;
    width: 213px;
    height: auto;
    padding: 10px;

    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 75px;
    background-color: rgba(255, 255, 255, 0.54);
}

.homepage-location-img-text {
    position: absolute;
    width: 287px;
    height: auto;
    right: 22px;
    bottom: 0px;
    padding: 10px;

    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 75px;
    text-align: right;
    background-color: rgba(255, 255, 255, 0.54);
}

.homepage-differentiation-img-text {
    position: absolute;
    bottom: 36px;
    left: 47px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 54px;
    background-color: rgba(255, 255, 255, 0.54);
    padding: 10px;
}

.homepage-activity-image-container,
.homepage-location-image-container,
.homepage-differentiation-image-container {
    position: relative;
    height: 90%;
    max-width: 60%;
    width: 60%;
}

.homepage-differentiation-image-container {
    width: 55%;
}

.homepage-activity-image,
.homepage-location-image,
.homepage-differentiation-image {
    position: absolute;
    text-align: center;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: filter 0.2s ease;
}

.homepage-activity-image:hover,
.homepage-location-image:hover,
.homepage-differentiation-image:hover {
    filter: brightness(0.7);
}

.homepage-activity-text-container,
.homepage-location-text-container {
    width: 40%;
}

.homepage-location-text-container {
    margin-right: 50px;
}

.homepage-differentiation-text-container {
    width: 35%;
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}

.lower-differentiation-text {
    margin-top: 20px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 34px;
}

.lower-differentiation-text > p {
    margin: 0 8% 0 8%;
}

@media (max-width: 1000px) {
    .homepage-activity-div,
    .homepage-location-div{
        height: auto;
        flex-direction: column-reverse;
        margin-bottom: 80px;
    }

    .homepage-activity-div {
        flex-direction: column;
    }

    .homepage-activity-image-container,
    .homepage-location-image-container {
        width: 90%;
        margin-bottom: 30px;
        max-width: unset;
    }

    .homepage-activity-image-container,
    .homepage-location-image-container {
        min-height: 600px;
    }

    .homepage-activity-text-container,
    .homepage-location-text-container {
        width: 80%;
    }

    .homepage-activity-img-text {
        position: absolute;
        left: 48px;
        top: 10px;
        width: 213px;
        margin-top: 0;
    }

    .homepage-tag-line-div {
        margin: 30px 20px 50px 20px;
        flex-direction: column;
    }

    .homepage-video {
        width: 80%;
        max-width: unset;
        margin-left: 0;
        margin-top: 20px;
    }

    .homepage-logo {
        width: 80%;
    }
}


@media screen and (max-width: 850px) {

    .homepage-differentiation-div {
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        height: unset;
        margin-bottom: 20px;
    }

    .homepage-differentiation-text-container {
        width: 90%;
        margin-bottom: 15px;
        margin-left: 0;
    }

    .homepage-differentiation-image-container {
        width: 90%;
        min-height: 400px;
        max-width: unset;
    }
}

@media (max-width: 600px) {

    .homepage-container {
        width: 100%;
    }

    .homepage-video {
        /*width: 100%;*/
    }

}

@media (max-width: 499px) {
    .top-shape {
        width: 100vw;
        min-width: unset;
        clip-path: polygon(0 0, 100% 0, 100% 54%, 68% 75%, 19% 70%, 0 45%);
    }

    .homepage-activity-image-container,
    .homepage-location-image-container {
        min-height: 400px;
    }

    .homepage-top-container {
        margin-top: 10px;
        margin-bottom: 50px;
    }

    .homepage-tag-line-div {
        margin: 0 20px 30px 15px;
    }

    .homepage-tag-line {
        font-size: 22px;
    }

    .homepage-title-div {
        margin-bottom: 80px;
        font-size: 25px;
        line-height: 35px;
        text-align: center;
        width: 95%;
    }

    .homepage-activity-img-text {
        font-size: 40px;
        line-height: 45px;
        left: 15px;
    }

    .homepage-differentiation-div {
        flex-direction: column-reverse;
        height: auto;
        margin-bottom: 15px;
    }

    .homepage-differentiation-text-container {
        width: 85%;
        margin-right: 0;
    }

    .homepage-differentiation-image-container {
        width: 85%;
        height: 400px;
    }

    .homepage-differentiation-img-text {
        font-size: 45px;
        bottom: 15px;
        margin: 0;
        left: 15px;
    }

    .homepage-subtitle {
        font-size: 16px;
        margin-top: 20px;
    }

    .homepage-location-img-text {
        font-size: 40px;
        line-height: 45px;
        bottom: 15px;
        margin: 0;
    }

    .homepage-location-div, .homepage-activity-div {
        margin-bottom: 60px;
    }

    .lower-differentiation-text {
        margin-top: 0;
    }

    .homepage-activity-text, .homepage-location-text, .homepage-differentiation-text, .lower-differentiation-text {
        font-size: 16px;
        line-height: 24px;
    }

    .homepage-differentiation-text {
        margin-top: 20px;
    }

    .homepage-activity-title, .homepage-location-title, .homepage-differentiation-title {
        font-size: 32px;
        line-height: 40px;
    }

}
