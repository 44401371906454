.outer-hovercard {
    font-weight: unset;
    font-size: unset;
    font-family: Open Sans, sans-serif;
    border: none;
    background-color: white;
}

.hover-card {
    --transition-time: 0.6s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: transparent;
    transition-duration: var(--transition-time);
    text-align: center;
    cursor: pointer;
    color: black;
    --card-background-color: var(--background-blue);
    --card-border-color: var(--secondary-background-color);
    text-decoration: none;
    /*border: solid var(--card-border-color);*/
    /*background: var(--card-border-color);*/
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

.hovercard-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: 0;
    top: 0;
}

.hovercard-card {
    transition: background var(--transition-time);
    height: 82.5%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.background {
    height: 70%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.fade-out {
    size: 0;
    opacity: 0;
}

.bottom-text {
    margin: 0px;
    filter: blur(0);
    position: relative;
    text-align: left;
    padding-left: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.color-block {
    background-color: white;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity var(--transition-time);
    z-index: 1000;
}

.hovercard-image-hover {
    opacity: 0.7;
}

.color-block-hover {
    transition: opacity var(--transition-time);
    opacity: 0.8;
}

.allow-overflow-text {
    white-space: initial;
}

.center-text {
    padding-left: 5%;
    padding-right: 5%;
    margin: 0px;
    align-self: center;
    width: 90%;
    /*transform: translateY(100%);*/
    transition: opacity var(--transition-time);
    z-index: 1001;
}

.card-bottom {
    height: 27.5%;
    padding-left: 5px;
    padding-right: 5px;
    background-color: var(--background-blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
}
