.App {
  /*padding: 5% 5% 5% 5%;*/
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: auto;
  min-width: var(--min-width);
  max-width: var(--max-width);
  /*padding-bottom: 50px;*/
  /*position: fixed;*/
  /*left: 0;*/
  /*top: 0;*/
  justify-content: center;
  align-items: center;
  width: 100%;
}

.App-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  height: 100%;
}

.App > *:nth-child(2)  {
  margin-top: 110px;
}

.hover-card-demo {
  margin: 5% 5% 5% 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-content: center;
  width: auto;
  height: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (min-width: 800px) {
  .App-wrapper {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 499px) {
  .App {
    min-width: unset;
    width: 100vw;
  }

}
