@import url(https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Open+Sans:wght@300;400;700&family=Overpass:wght@100;300;400;700&family=Roboto:wght@700&display=swap);
.App {
  /*padding: 5% 5% 5% 5%;*/
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: auto;
  min-width: var(--min-width);
  max-width: var(--max-width);
  /*padding-bottom: 50px;*/
  /*position: fixed;*/
  /*left: 0;*/
  /*top: 0;*/
  justify-content: center;
  align-items: center;
  width: 100%;
}

.App-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-content: flex-start;
  width: 100%;
  height: 100%;
}

.App > *:nth-child(2)  {
  margin-top: 110px;
}

.hover-card-demo {
  margin: 5% 5% 5% 5%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: left;
  align-content: center;
  width: auto;
  height: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (min-width: 800px) {
  .App-wrapper {
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 499px) {
  .App {
    min-width: unset;
    width: 100vw;
  }

}

.top-scroll-link {
    text-decoration: none;
    color: inherit;
}
.outer-hovercard {
    font-weight: unset;
    font-size: unset;
    font-family: Open Sans, sans-serif;
    border: none;
    background-color: white;
}

.hover-card {
    --transition-time: 0.6s;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    background-color: transparent;
    transition-duration: var(--transition-time);
    text-align: center;
    cursor: pointer;
    color: black;
    --card-background-color: var(--background-blue);
    --card-border-color: var(--secondary-background-color);
    text-decoration: none;
    /*border: solid var(--card-border-color);*/
    /*background: var(--card-border-color);*/
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.4);
}

.hovercard-image {
    height: 100%;
    width: 100%;
    object-fit: cover;
    position: absolute;
    display: flex;
    flex-direction: row;
    justify-content: center;
    left: 0;
    top: 0;
}

.hovercard-card {
    transition: background var(--transition-time);
    height: 82.5%;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

.background {
    height: 70%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
}

.fade-out {
    size: 0;
    opacity: 0;
}

.bottom-text {
    margin: 0px;
    -webkit-filter: blur(0);
            filter: blur(0);
    position: relative;
    text-align: left;
    padding-left: 2px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.color-block {
    background-color: white;
    opacity: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: opacity var(--transition-time);
    z-index: 1000;
}

.hovercard-image-hover {
    opacity: 0.7;
}

.color-block-hover {
    transition: opacity var(--transition-time);
    opacity: 0.8;
}

.allow-overflow-text {
    white-space: normal;
    white-space: initial;
}

.center-text {
    padding-left: 5%;
    padding-right: 5%;
    margin: 0px;
    align-self: center;
    width: 90%;
    /*transform: translateY(100%);*/
    transition: opacity var(--transition-time);
    z-index: 1001;
}

.card-bottom {
    height: 27.5%;
    padding-left: 5px;
    padding-right: 5px;
    background-color: var(--background-blue);
    display: flex;
    flex-direction: column;
    justify-content: center;
}


.spinner,
.spinner:before,
.spinner:after {
    border-radius: 50%;
}
.spinner {
    color: var(--accent-color);
    font-size: 11px;
    text-indent: -99999em;
    margin: 55px auto;
    position: relative;
    width: 10em;
    height: 10em;
    box-shadow: inset 0 0 0 1em;
    transform: translateZ(0);
}
.spinner:before,
.spinner:after {
    position: absolute;
    content: '';
}
.spinner:before {
    width: 5.2em;
    height: 10.2em;
    background: white;
    border-radius: 10.2em 0 0 10.2em;
    top: -0.1em;
    left: -0.1em;
    transform-origin: 5.1em 5.1em;
    -webkit-animation: load2 2s infinite ease 1.5s;
    animation: load2 2s infinite ease 1.5s;
}
.spinner:after {
    width: 5.2em;
    height: 10.2em;
    background: white;
    border-radius: 0 10.2em 10.2em 0;
    top: -0.1em;
    left: 4.9em;
    transform-origin: 0.1em 5.1em;
    -webkit-animation: load2 2s infinite ease;
    animation: load2 2s infinite ease;
}
@-webkit-keyframes load2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
@keyframes load2 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.hover-card-container,
.hover-card-container-straight {
    margin-bottom: 40px;
    width: 100%;
}

.hover-card-container > *,
.hover-card-container-straight > * {
    margin: 30px 30px 10px;
}

.row-based-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-content: center;
    height: auto;
}

.side-scroll-container {
    width: 100%;
    max-width: 100vw;
    display: flex;
    justify-content: left;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
}

/* Hide scrollbar for Chrome, Safari and Opera */
/*.side-scroll-container::-webkit-scrollbar {*/
/*    display: none*/
/*}*/

/*.side-scroll-container {*/
/*    -ms-overflow-style: none;  !* IE and Edge *!*/
/*    scrollbar-width: none;  !* Firefox *!*/
/*}*/

.show-more-button {
    width: 60%;
    max-width: 400px;
    border: none;
    height: 55px;
    background-color: #C4C4C4;;
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 35px;
}

.show-more-button:hover {
    cursor: pointer;
    -webkit-filter: brightness(80%);
            filter: brightness(80%);
}

@media screen and (max-width: 1200px) {
    .hover-card-container-straight > * {
        margin: 15px 15px 10px;
    }
}

@media screen and (max-width: 539px) {
    .hover-card-container > *:nth-child(odd) {
        margin-right: 10px;
        margin-left: 10px;
    }

    .hover-card-container > *:nth-child(even) {
        margin-right: 10px;
        margin-left: 10px;
    }
}

@media screen and (max-width: 499px) {
    .hover-card-container-straight > * {
        margin: 8px 8px 10px;
    }

    .hover-card-container > *:nth-child(odd) {
        margin-right: 5px;
        margin-left: 2px;
    }

    .hover-card-container > *:nth-child(even) {
        margin-right: 0px;
        margin-left: 2px;
    }

    .show-more-button {
        width: 70%;
    }
}

.top-shape {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    background-color: var(--background-blue);
    -webkit-clip-path: polygon(0 0, 100% 0, 100% 54%, 68% 84%, 19% 73%, 0 54%);
            clip-path: polygon(0 0, 100% 0, 100% 54%, 68% 84%, 19% 73%, 0 54%);
    height: 100%;
    min-height: 30vh;
    min-width: var(--min-width);
    width: 100%;
}

.homepage-container {
    width: 95%;
}

.homepage-top-container {
    margin-top: 50px;
    margin-bottom: 50px;
}

.homepage-tag-line-div {
    width: auto;
    height: 80%;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin: 40px 0 90px 0;
}

.homepage-logo {
    /*min-width: 620px;*/
    width: 30%;
    align-self: center;
    margin-bottom: 30px;
}

.homepage-video {
    width: 60%;
    /*min-width: 710px;*/
    margin-left: 15px;
    height: auto;

}

.homepage-activity-div,
.homepage-location-div,
.homepage-differentiation-div {
    display: flex;
    flex-direction: row;
    height: 900px;
    margin-bottom: 10px;
    align-items: center;
}

.homepage-differentiation-div {
    height: 730px;
    justify-content: center;
    flex-direction: row-reverse;
    margin-bottom: 50px;
}

.homepage-subtitle {
    margin-top: 40px;
    font-family: Open Sans, serif;
    width: 90%;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 26px;
}

.homepage-activity-title,
.homepage-location-title {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 50px;
    height: auto;
    margin-bottom: 10px;
}

.homepage-title-div {
    width: 85%;
    text-align: left;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 50px;
    font-family: Overpass, sans-serif;
    font-style: normal;
    /*font-weight: bold;*/
    font-size: 40px;
    line-height: 50px;
}

.homepage-activity-text,
.homepage-location-text,
.homepage-differentiation-text {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 34px;
}

.homepage-activity-img-text {
    position: absolute;
    left: 88px;
    top: 36px;
    width: 213px;
    height: auto;
    padding: 10px;

    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 75px;
    background-color: rgba(255, 255, 255, 0.54);
}

.homepage-location-img-text {
    position: absolute;
    width: 287px;
    height: auto;
    right: 22px;
    bottom: 0px;
    padding: 10px;

    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 75px;
    text-align: right;
    background-color: rgba(255, 255, 255, 0.54);
}

.homepage-differentiation-img-text {
    position: absolute;
    bottom: 36px;
    left: 47px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 54px;
    background-color: rgba(255, 255, 255, 0.54);
    padding: 10px;
}

.homepage-activity-image-container,
.homepage-location-image-container,
.homepage-differentiation-image-container {
    position: relative;
    height: 90%;
    max-width: 60%;
    width: 60%;
}

.homepage-differentiation-image-container {
    width: 55%;
}

.homepage-activity-image,
.homepage-location-image,
.homepage-differentiation-image {
    position: absolute;
    text-align: center;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: -webkit-filter 0.2s ease;
    transition: filter 0.2s ease;
    transition: filter 0.2s ease, -webkit-filter 0.2s ease;
}

.homepage-activity-image:hover,
.homepage-location-image:hover,
.homepage-differentiation-image:hover {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
}

.homepage-activity-text-container,
.homepage-location-text-container {
    width: 40%;
}

.homepage-location-text-container {
    margin-right: 50px;
}

.homepage-differentiation-text-container {
    width: 35%;
    display: flex;
    flex-direction: column;
    margin-left: 50px;
}

.lower-differentiation-text {
    margin-top: 20px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 22px;
    line-height: 34px;
}

.lower-differentiation-text > p {
    margin: 0 8% 0 8%;
}

@media (max-width: 1000px) {
    .homepage-activity-div,
    .homepage-location-div{
        height: auto;
        flex-direction: column-reverse;
        margin-bottom: 80px;
    }

    .homepage-activity-div {
        flex-direction: column;
    }

    .homepage-activity-image-container,
    .homepage-location-image-container {
        width: 90%;
        margin-bottom: 30px;
        max-width: unset;
    }

    .homepage-activity-image-container,
    .homepage-location-image-container {
        min-height: 600px;
    }

    .homepage-activity-text-container,
    .homepage-location-text-container {
        width: 80%;
    }

    .homepage-activity-img-text {
        position: absolute;
        left: 48px;
        top: 10px;
        width: 213px;
        margin-top: 0;
    }

    .homepage-tag-line-div {
        margin: 30px 20px 50px 20px;
        flex-direction: column;
    }

    .homepage-video {
        width: 80%;
        max-width: unset;
        margin-left: 0;
        margin-top: 20px;
    }

    .homepage-logo {
        width: 80%;
    }
}


@media screen and (max-width: 850px) {

    .homepage-differentiation-div {
        flex-direction: column-reverse;
        justify-content: space-evenly;
        align-items: center;
        height: unset;
        margin-bottom: 20px;
    }

    .homepage-differentiation-text-container {
        width: 90%;
        margin-bottom: 15px;
        margin-left: 0;
    }

    .homepage-differentiation-image-container {
        width: 90%;
        min-height: 400px;
        max-width: unset;
    }
}

@media (max-width: 600px) {

    .homepage-container {
        width: 100%;
    }

    .homepage-video {
        /*width: 100%;*/
    }

}

@media (max-width: 499px) {
    .top-shape {
        width: 100vw;
        min-width: unset;
        -webkit-clip-path: polygon(0 0, 100% 0, 100% 54%, 68% 75%, 19% 70%, 0 45%);
                clip-path: polygon(0 0, 100% 0, 100% 54%, 68% 75%, 19% 70%, 0 45%);
    }

    .homepage-activity-image-container,
    .homepage-location-image-container {
        min-height: 400px;
    }

    .homepage-top-container {
        margin-top: 10px;
        margin-bottom: 50px;
    }

    .homepage-tag-line-div {
        margin: 0 20px 30px 15px;
    }

    .homepage-tag-line {
        font-size: 22px;
    }

    .homepage-title-div {
        margin-bottom: 80px;
        font-size: 25px;
        line-height: 35px;
        text-align: center;
        width: 95%;
    }

    .homepage-activity-img-text {
        font-size: 40px;
        line-height: 45px;
        left: 15px;
    }

    .homepage-differentiation-div {
        flex-direction: column-reverse;
        height: auto;
        margin-bottom: 15px;
    }

    .homepage-differentiation-text-container {
        width: 85%;
        margin-right: 0;
    }

    .homepage-differentiation-image-container {
        width: 85%;
        height: 400px;
    }

    .homepage-differentiation-img-text {
        font-size: 45px;
        bottom: 15px;
        margin: 0;
        left: 15px;
    }

    .homepage-subtitle {
        font-size: 16px;
        margin-top: 20px;
    }

    .homepage-location-img-text {
        font-size: 40px;
        line-height: 45px;
        bottom: 15px;
        margin: 0;
    }

    .homepage-location-div, .homepage-activity-div {
        margin-bottom: 60px;
    }

    .lower-differentiation-text {
        margin-top: 0;
    }

    .homepage-activity-text, .homepage-location-text, .homepage-differentiation-text, .lower-differentiation-text {
        font-size: 16px;
        line-height: 24px;
    }

    .homepage-differentiation-text {
        margin-top: 20px;
    }

    .homepage-activity-title, .homepage-location-title, .homepage-differentiation-title {
        font-size: 32px;
        line-height: 40px;
    }

}

.link {
    --backgroundcolor: transparent;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: var(--backgroundcolor);
    text-decoration: none;
    color: rgb(59, 59, 59);
    padding: 10px;
    /*border-bottom: 2px solid var(--background-blue);*/
    box-sizing: border-box;
    vertical-align: middle;
    max-width: 250px;
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 25px;
    line-height: 46px;
    text-align: center;
    height: 90%;
}

.link:hover {
    border-bottom: 2px solid black;
    color: black;
}

/*
    Special rule for the WHY WE'RE DIFFERENT link
 */
.link:last-child {
    margin-left: 30px;
    font-size: 30px;
}

.navbar {
    background-color: var(--background-blue);
    position: fixed;
    top: 0;
    left: 0;
    height: 120px;
    width: 100%;
    align-self: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    z-index: 100000;
}

.navbar > * {
    background-color: transparent;
}

@media screen and (max-width: 984px) {
    .link {
        text-align: left;
    }
    .link:last-child {
        font-size: 25px;
        margin-left: 0;
    }

}

@media screen and (max-width: 499px) {
    .navbar {
        height: 120px;
        margin-bottom: 20px;
    }
}

.burger-container {
    display: inline-block;
    cursor: pointer;
}

.burger-bar1, .burger-bar2, .burger-bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

/* Rotate first bar */
.burger-change .burger-bar1 {
    transform: rotate(-45deg) translate(-9px, 6px) ;
}

/* Fade out the second bar */
.burger-change .burger-bar2 {
    opacity: 0;
}

/* Rotate last bar */
.burger-change .burger-bar3 {
    transform: rotate(45deg) translate(-8px, -8px) ;
}

.menu-container {
    position: fixed;
    left: 0;
    top: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 50px 20px;
}

.menu-background {
    /*background-color: #6e6e6e;*/
    background-color: var(--background-blue);
}

.menu-link:hover {
    /*text-decoration: underline;*/
}
label {
    display: block;
    font: .9rem 'Fira Sans', sans-serif;
}

input[type='submit'],
label {
    margin-top: 1rem;
}

.login-page {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.admin-information-page {
    padding-top: 100px;
}

.admin-errors-row {
    display: flex;
    margin: 10px;
    justify-content: space-between;
}

.admin-errors {
    margin: 20px;
    border: solid black;
}


.pdf-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.pdf-page-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    height: 100vh;
    text-align: center;
    padding-top: 40px;
}

.pdf-page-title {
    font-size: 3vh;
}
.slider-container {
    align-items: center;
    width: 100%;
}

.large-screen-slider {
    width: 70%;
}

.slider {
    /*width: 60%;*/
    width: 60%;
}

.slider-side-text {
    margin: 10px;
}

.label {
    width: 15%;
    text-align: center;
}

.bottom-slider-section {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.left-slider-text {
    text-align: center;
}

.bottom-left-text {
    margin-right: 30px;
}

.right-slider-text {
    /*text-align: right;*/
}


.filter-bar {
    width: 100%;
    background-color: var(--background-blue);
    display: flex;
    flex-direction: column;
    padding: 35px 0 35px 0;
    margin-top: 20px;
    font-family: Open Sans, sans-serif;
}

.filter-bar-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 50px;
}

.filter-bar-row > * {
    margin: 0;
}

.filter-bar-right {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
}

.filter-bar-title {
    width: 30%;
    font-size: 25px;
    font-family: Overpass, sans-serif;
    font-weight: bold;
}
.filter-bar-left-label {
    text-align: center;
    width: 60px;
}

.filter-bar-slider {
    width: 70%;
    margin-right: 20px;
    margin-left: 20px;
}

.filter-bar-bottom-text {
    width: 80%;
    align-self: center;
    margin-top: 40px;
}

@media (max-width: 900px) {
    .filter-bar-row {
        flex-direction: column;
        align-items: center;
    }
    .filter-bar-right {
        width: 100%;
    }
    .filter-bar-title {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        font-size: 25px;
    }
}

@media (max-width: 499px) {
    .filter-bar-row, .filter-bar-right {
        font-size: 16px;
    }

    .filter-bar-title {
        font-size: 22px;
    }
}

.search-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    min-width: 411px;
}

.search-header {
    width: 90%;
}

.search-title {
    font-size: 55px;
}

.bottom-search-section {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
}

.bold {
    font-weight: bold;
}

.search-page-search-bar {
    width: 50%;
    margin-top: 20px;
}

.search-page-selector {
    margin-top: 20px;
    background-color: var(--secondary-background-color);
    width: 20%;
    /*padding: 60px 0 0 0;*/
    display: flex;
    flex-direction: column;
    max-width: 280px;
    height: 100%;
}

.search-selector-separator {
    width: 85%;
    /*border: 1px solid var(--primary-text-color);*/
}

.search-page-selector > * {
    margin: 0 15px 0 15px;
}

.selector-option {
    margin: 20px;
    font-size: 16px;
    text-color: var(--primary-text-color);
}

.selector-option:hover {
    color: var(--accent-color);
    cursor: pointer;
}

.other-filters {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 30px 0;
    width: 100%;
    /*background-color: var(--accent-color);*/
}

.other-filters-option {
    width: 80%;
    color: var(--primary-text-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 4px 20px;
}

.other-filters-option:first-child {
    margin-top: 8px;
}

@media (max-width: 950px) {
    .bottom-search-section {
        flex-direction: column;
    }

    .other-filters-option:first-child {
        margin-top: 4px;
    }

    .search-page-selector {
        flex-direction: row;
        justify-content: space-evenly;
        width: auto;
        height: auto;
        padding: 0;
        max-width: none;
    }

    .other-filters {
        margin: 0;
        height: 200px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        height: auto;
        width: 570px;
    }

    .other-filters-option {
        width: 45%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .search-page-options {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .search-selector-separator {
        width: auto;
    }
}

@media screen and (max-width: 630px) {

    .search-title {
        font-size: 45px;
    }

    .search-page-selector {
        flex-direction: column;
        align-items: center;
    }

    .search-selector-separator {
        width: 100%;
    }

    .search-page-options {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .selector-option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: auto;
    }

    .other-filters {
        width: auto;
    }
}

@media (max-width: 499px) {
    .other-filters-option {
        margin-left: 9px;
        margin-right: 9px;
    }

    .search-page {
        margin-left: 25px;
        padding-top: 20px;
    }

    .search-page-search-bar {
        width: 80%;
        margin-top: 40px;
    }
}

.search-div {
    width: 100%;
}

/* Style the search field */
.search-input {
    padding: 10px;
    font-size: 17px;
    border: 1px solid grey;
    float: left;
    width: 80%;
    background: #f1f1f1;
}

/* Style the search button */
.search-button {
    float: left;
    width: 20%;
    padding: 10px;
    background: var(--accent-color);
    color: white;
    font-size: 17px;
    border: 1px solid grey;
    border-left: none; /* Prevent double borders */
    cursor: pointer;
}

.search-button:hover {
    background: #0b7dda;
}

.search-form {
    display: flex;
    flex-direction: row;
}

/* Clear floats */
.search-form::after {
    content: "";
    clear: both;
    display: table;
}

.org-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100vw;
    max-width: 1500px;
    padding-top: 50px;
    padding-bottom: 10px;
}

.social-icons-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.paragraph-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    height: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
}

.org-cost-block {
    background-color: var(--background-blue);
    width: 70%;
    font-weight: bold;
    padding: 5px 7%;
}

.info-section-header {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
}

.info-section-subheader {
    margin-bottom: 15px;
    margin-top: 5px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
}

.info-section-subheader:nth-child(2) {
    margin-bottom: 0;
    margin-top: 0;
}

.paragraph-text {
    font-size: 17px;
}

.top-images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40%;
    max-height: 490px;
}

.top-image-container,
.org-image-container {
    width: 47.5%;
    height: 100%;
    align-self: center;
}

.org-image-container {
    height: 550px;
}

.org-page-additional-info {
    align-self: center;
    height: auto;
}

.top-image-container > img,
.org-image-container > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.org-image-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-height: 550px;
    margin-bottom: 25px;
}

.bottom-paragraph-caption {
    margin-right: 20%;
    align-self: flex-end;
    text-wrap: none;
}

.org-carousel-image {
    display: flex;
    align-items: center;
    width: 100%;
    height: 400px;
}

.org-carousel-image > div > img {
    height: 100%;
    align-self: center;
}

.paragraph-caption {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 15px;
}

.info-section {
    width: 40%;
    margin-left: 1.25%;
    max-width: 480px;
    font-size: 18px;
    margin-bottom: 25px;
}

.info-block {
    background: var(--secondary-background-color);
    padding: 64px 48px 12px 48px;
}

.info-line {
    margin: 8px 0 20px 0;
}

.who-we-are-section {
    margin-bottom: -250px;
    margin-top: 25px;
    padding: 62px 5% 62px 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: var(--background-blue);
}

.who-we-are-section > *:first-child {
    margin-bottom: 20px;
}

.why-your-work-matters-section {
    background: #4878B1;
    margin-top: 50px;
    padding: 140px 8% 85px;
    z-index: -10;
}

.cost-block {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 120px;
}

.org-page-testimonial {
    margin-top: 75px;
    margin-bottom: 75px;
}


.org-page-upper-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 50px;
    width: 100%;
    height: 50%;
}

.testimonial-div {
    /*margin-bottom: 5%;*/
    text-align: center;
}

.org-page-upper-right {
    display: flex;
    flex-direction: column;
    width: 55%;
    margin-right: 1.25%;
    justify-content: space-evenly;
    align-content: center;
    padding-bottom: 40px;
}

.org-page-box-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.always-top-box-section {
    height: 50%;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*border: solid thin black;*/
    width: 50%;
    margin: 0 5px 0 5px;
}

.box-image {
    max-width: 100%;
    align-self: center;
}

.box-text {

}

.additional-org-info-title {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 20px;
}

.cost-dropdown {
    width: 60%;
}

.cost-dropdown:hover {
    cursor: pointer;
}

.cost-select {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-direction: row;
    margin-bottom: 15px;
}

.cost-text {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
}

.cost-lower-text {
    margin-top: 5px;
    text-align: center;
    font-size: 16px;
    width: 80%;
    align-self: center;
}

.left-box {
    margin-right: 3%;
}

.right-box {
    margin-left: 3%;
}

.more-info {
    text-align: center;
    height: 60px;
    width: 100%;
    align-self: center;
    background-color: var(--accent-color);
    color: black;
    line-height: 60px;
    text-decoration: none;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Open Sans, sans-serif;
    font-weight: bold;
}

.socials-div {
    display: flex;
    flex-direction: row;
    align-self: center;
}

.socials-component {
    margin: 0 5px 0 5px;
}

.socials-component:hover {
    cursor: pointer;
    -webkit-filter: brightness(120%);
            filter: brightness(120%);
}

.more-info:hover {
    -webkit-filter: brightness(120%);
            filter: brightness(120%);
    cursor: pointer;
}

@media (max-width: 1199px) {

    .org-page-upper-section {
        margin-bottom: 0;
    }

    .who-we-are-section {
        max-width: 75%;
        margin-bottom: -150px;
    }

    .org-page-upper-right {
        width: 50%;
        margin-left: 0;
        padding-bottom: 0;
    }

    .org-page-box-section {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .bottom-box-section {
        width: 100%;
        align-items: center;
        flex-direction: row;
    }

    .box {
        width: 100%;
        margin-top: 3%;
    }

    .left-box {
        margin-right: 0;
    }

    .right-box {
        margin-left: 0;
    }

    .top-images {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-height: none;
    }

    .top-image-container {
        height: 47.5%;
        width: 100%;
        max-height: 450px;
    }

    .top-image-container:first-child {
        margin-bottom: 15px;
    }

}

@media (max-width: 699px) {

    .info-section {
        margin-left: 0;
    }

    .info-block {
        padding: 32px 24px 12px 24px;
    }

    .org-page-container {
        align-items: center;
        flex-direction: column;
    }

    .org-page-upper-section {
        align-items: center;
        flex-direction: column;
    }

    .org-page-upper-right {
        width: 100%;
        align-items: center;
        margin-right: 0;
    }

    .org-page-box-section {
        align-items: center;
        flex-direction: column;
    }

    .info-section {
        width: 75%;
        max-width: none;
    }

    .bottom-paragraph-caption {
        align-self: flex-start;
        margin-right: 0;
    }

    .who-we-are-section {
        margin-bottom: 25px;
    }

    .why-your-work-matters-section {
        margin-bottom: 10px;
    }

    .who-we-are-section {
        max-width: none;
    }

    .why-your-work-matters-section {
        padding-top: 85px;
    }
}

@media (max-width: 499px) {

    .paragraph-text {
        font-size: 14px;
        line-height: 24px;
    }

    .paragraph-caption {
        font-size: 28px;
        margin-bottom: 5px;
    }

    .info-section {
        font-size: 18px;
    }

    .additional-org-info-title {
        font-size: 28px;
    }

    .cost-lower-text {
        font-size: 16px;
    }

}

.testimonial-image-container {
    width: 30%;
    height: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.testimonial-image {
    min-width: 140px;
    min-height: 140px;
    max-height: 260px;
    max-width: 260px;
    width: 100%;
    height: auto;
    /*padding-top: 100%;*/
    width: 20vw;
    height: 20vw;
    border-radius: 50%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    position: relative;
}

.testimonial-text {
    position: relative;
    display: inline-block;
    padding: 2%;
    width: 100%;
    vertical-align: middle;
    font-style: italic;
}

.testimonial-name {
    align-self: flex-end;
    margin-right: 2%;
    margin-top: 10px;
}

.align-left {
    align-self: flex-start;
    margin-left: 2%;
    margin-right: 0;
}

.testimonial-container {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.testimonial-text-container {
    display: flex;
    flex-direction: column;
    margin: 25px;
    font-size: 20px;
    width: 80%;
}

@media screen and (max-width: 700px) {
    .testimonial-text-container {
        font-size: 16px;
        margin: 5px 0;
    }

    .testimonial-container > *:first-child {
        margin-right: 50px;
    }

    .align-left {
        margin-right: 50px;
    }

    .testimonial-container {
        padding: 10px;
    }
}

@media screen and (max-width: 499px) {
    .testimonial-text {
        font-size: 14px;
    }
}


@media screen and (max-width: 450px) {
    .testimonial-container > *:first-child {
        margin-right: 80px;
    }
}


.howto-page {
    max-width: 1200px;
    padding-top: 80px;
    width: 100%;
}

.howto-top-container {
    display: flex;
    flex-direction: row;
    min-height: 640px;
    margin-right: 3%;
    margin-left: 3%;
}

.howto-top-text {
    justify-content: center;
}

.howto-top-image {
    max-height: 640px;
    width: 50%;
    margin-right: 70px;
    object-fit: cover;
}

.howto-bottom-image {
    max-height: 480px;
    width: 50%;
    margin-right: 70px;
    object-fit: cover;
}

.howto-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.howto-title {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 62px;
    margin-bottom: 20px;
}

.howto-body-text {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 31px;
}

.howto-lines {
    margin-left: 5%;
    margin-right: 5%;
}

.read-more-button {
    height: 80px;
    width: 250px;
    text-align: center;
}

.howto-button {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    align-self: center;
    /*border: 1px solid lightslategray;*/
    border: none;
    transition: ease filter 0.2s;
    padding-left: 10px;
    text-wrap: none;
    background-color: var(--background-blue);
}

.howto-button:hover {
    -webkit-filter: brightness(0.85);
            filter: brightness(0.85);
}

.howto-button:hover {
    cursor: pointer;
}

.read-more-button:active {
    text-decoration: underline;
}

.howto-line-text {
    margin-right: 50px;
    width: 70%;
}


.howto-bottom-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 3% 20px;
}

.howto-bottom-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: center;
    margin-right: 15px;
}

.howto-bottom-text {
    margin-bottom: 50px;
}

.download-checklist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: inherit;
    color: inherit;
    height: 160px;
    width: 100%;
    max-width: 560px;
    text-align: center;
}

@media screen and (max-width: 999px) {
    .howto-bottom-container {
        flex-direction: column;
    }

    .howto-bottom-image {
        width: 80%;
        margin-right: 0;
    }

    .howto-bottom-info {
        width: 80%;
    }

    .howto-bottom-text {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 700px) {
    .howto-top-container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .howto-top-text {
        align-items: center;
        margin-bottom: 50px;
    }

    .howto-top-image {
        margin: 0;
        width: 90%;
    }

    .howto-bottom-image {
        margin-right: 0;
    }

    .howto-bottom-container {
        flex-direction: column;
        align-items: center;
    }

    .howto-bottom-info {
        width: 80%;
    }

    .howto-lines {
        align-items: center;
        margin-bottom: 10px;
    }

    .howto-title {
        margin-bottom: 10px;
        text-align: center;
    }

    .howto-info {
        flex-direction: column;
        align-items: center;
    }

    .howto-info > *:first-child {
        margin-bottom: 25px;
        margin-right: 0;
        width: 100%;
    }
}

@media (max-width: 499px) {

    .howto-page {
        padding-top: 0;
    }

    .howto-top-container {
        margin-bottom: 20px;
    }

    .howto-top-text {
        margin-bottom: 30px;
    }

    .download-checklist {
        height: 70px;
    }

    .howto-title {
        font-size: 28px;
        line-height: 36px;
    }

    .howto-body-text {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .howto-info > *:first-child {
        margin-bottom: 10px;
        margin-right: 0;
        width: 100%;
    }

    .read-more-button {
        text-align: center;
        padding: 0;
    }

    .howto-button {
        width: 150px;
        height: 50px;
        font-size: 16px;
    }
}

.info-page {
    max-width: 1300px;
    width: 90vw;
}

.info-page-title {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 81px;
    margin-bottom: 15px;
}

.info-page-text {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 33px;
}

.see-all-locations-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.see-all-locations {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 56px;
    margin-bottom: 15px;
}

.info-page-tagline {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 43px;
    margin-left: 10%;
}

.info-page-short-link-block {
    height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.info-page-top-section {
    margin-bottom: 30px;
}

.info-page-rotating-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 715px;
}

.info-page-rotating-block > * {
    width: 46%;
}

.info-page-rotating-block > a,
.info-page-bottom-link-container > a {
    color: inherit;
}

.info-page-img-container {
    position: relative;
    height: 90%;
    border: solid black;
    max-height: 600px;
}

.info-page-img-container > img {
    text-align: center;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: -webkit-filter 0.2s ease;
    transition: filter 0.2s ease;
    transition: filter 0.2s ease, -webkit-filter 0.2s ease;
}

.info-page-img-container:hover > img {
    -webkit-filter: brightness(0.7);
            filter: brightness(0.7);
}

.info-page-img-container > div {
    position: absolute;
    font-family: Open Sans, sans-serif;
    background-color: var(--clear-white);
    padding: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 75px;
}

.info-page-bottom-link-container {
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.info-page-bottom-link-container > * {
    height: 46%;
}

.activity-page-bottom-link-container > * {

}

.activity-page-bottom-link-container {
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.info-page-more-info {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 56px;
}

.more-info-block {
    max-width: 520px;
    margin-right: 10px;
}

.voluntourism-quote {
    font-family: Overpass , sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 55px;
    max-width: 600px;
    margin-right: 25px;
}

.image-text {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 70px;
    line-height: 95px;
}

.see-all-img {
    height: 200px;
    width: 60%;
    max-width: 600px;
}

.more-info-text {
    width: 400px;
}

@media (max-width: 850px) {
    .info-page-rotating-block,
    .info-page-short-link-block {
        flex-direction: column;
        height: auto
    }

    .voluntourism-quote {
        margin-right: 0;
    }

    .info-page-short-link-block {
        margin-top: 30px;
    }

    .info-page-rotating-block > *,
    .info-page-short-link-block > * {
        width: 90%;
        margin-bottom: 30px;
    }

    .info-page-bottom-link-container > * {
        width: 100%;
        height: auto;
        justify-content: flex-start;
        margin-bottom: 30px;
    }

    .info-page-bottom-link-container {
        height: auto;
        margin-bottom: 0;
    }

    .info-page-short-link-block {

    }

    .more-info-text {
        width: auto;
    }


    .info-page-img-container {
        height: 300px;
    }

    .see-all-locations-block {
        /*height: 250px;*/
    }

    .info-page-top-section {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 499px) {

    .info-page-img-container {
        height: 200px;
    }

    .info-page-top-section {
        margin-bottom: 30px;
    }

    .info-page-title {
        margin-top: 10px;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
    }

    .info-page-text {
        font-size: 14px;
        line-height: 22px;
        margin-top: 5px;
    }

    .info-page-tagline {
        font-size: 20px;
        line-height: 25px;
    }

    .see-all-locations {
        margin: 0;
        font-size: 28px;
    }

    .info-page-img-container > div {
        font-size: 35px;
        line-height: 45px;
    }

    .voluntourism-quote {
        font-size: 25px;
        line-height: 35px;
    }

    .info-page-more-info {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 0;
    }

    .info-page {
        padding-top: 0;
    }
}


.see-all-programs-div {
    margin-top: 40px;
    height: 150px;
}

.activity-page-bottom-link-container > * {
    color: inherit;
}

.medical-box {
    max-height: 600px;
}

@media screen and (max-width: 850px) {
    .see-all-programs-div {
        margin-top: 30px;
        margin-bottom: 50px;
    }

    .activity-page-bottom-link-container {
        height: auto;
    }
}

@media screen and (max-width: 499px) {
    .see-all-programs-div {
        margin-bottom: 30px;
    }

    .activity-page-bottom-link-container {
        margin-bottom: 0;
    }
}

.extendedhowto-container {
    padding: 40px 50px 15px;
    max-width: 1200px;
}

.extended-howto-section-one,
.extended-howto-section-two {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.extended-howto-section-one > img,
.extended-howto-section-two > img {
    width: 60%;
    max-height: 100%;
}

.extended-howto-header {
    font-family: Overpass;
    font-style: normal;
    font-weight: bold;
    font-size: 60px;
    line-height: 107px;
    margin-bottom: 30px;
    max-width: 1200px;
}

.extended-howto-paragraph {

}

.extended-howto-text {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 31px;
    max-width: 1200px;
}

.extended-howto-section-one {
    height: 500px;
    margin-top: 80px;
    margin-bottom: 50px;
}

.extended-howto-section-one > img {
    height: 100%;
    object-fit: cover;
    margin-right: 50px;
}

.extended-howto-section-one > p {
    max-width: 500px;
}

.extended-howto-section-two {
    height: 640px;
}

.extended-howto-section-two > img {
    height: 100%;
    object-fit: cover;
    margin-left: 50px;
}

.extended-howto-section-two > p {
    max-width: 430px;
}

@media (max-width: 850px) {

    .extended-howto-section-one > img,
    .extended-howto-section-two > img {
        width: 90%;
    }

    .extended-howto-section-two {
        flex-direction: column-reverse;
        height: auto;
    }
    .extended-howto-section-one {
        flex-direction: column;
        height: auto;
        margin-top: 30px;
    }

    .extended-howto-section-one > img {
        height: 380px;
        margin: 0;
    }

    .extended-howto-section-two > img {
        height: 400px;
        margin: 0;
    }

    .extended-howto-header {
        font-size: 35px;
        line-height: 45px;
        margin-bottom: 5px;
    }

    .extended-howto-text {
        font-size: 14px;
        line-height: 24px;
    }

    .extended-howto-header,
    .extended-howto-text,
    .extended-howto-section-one > p,
    .extended-howto-section-two > p {
        width: auto;
        padding-left: 5px;
        padding-right: 5px;
    }

    .extended-howto-section-one > p,
    .extended-howto-section-two > p {
        max-width: unset;
        width: 90%;
        margin-top: 15px;
    }

    .extendedhowto-container {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.about-page {
    padding-top: 80px;
    padding-left: 5%;
    padding-right: 5%;
    align-items: center;
    max-width: 1200px;
    width: 90vw;
}

.about-top-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 80px;
}

.about-top-image {
    object-fit: cover;
    width: 60%;
    margin-right: 100px;
    height: 600px;
}

.about-mid-text {
    margin-bottom: 50px;
}

.about-us-section,
.our-difference-section {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.our-difference-section {
    margin-bottom: 50px;
}

.about-us-section > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.our-difference-section > *:first-child {
    width: 45%;
    margin-right: 50px;
}

.about-us-section > *:first-child,
.our-difference-section > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: flex-start;
    margin-right: 50px;
}

.about-us-image {
    object-fit: cover;
    width: 50%;
    height: 550px;
}

.about-body-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 34px;
}

.about-title, .about-subtitle {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 77px;

    margin-bottom: 30px;
}

.about-subtitle {
    margin-bottom: 10px;
}

.about-ning {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.about-ning > * {
    width: 45%;
    height: auto
}

@media (max-width: 1099px) {
    .about-body-text {
        font-size: 20px;
        line-height: 28px;
    }

    .about-title {
        font-size: 40px;
        line-height: 55px;
    }
}

@media (max-width: 999px) {
    .about-top-image {
        width: 50%;
    }
}

@media (max-width: 699px) {
    .about-top-section {
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 50px;
    }

    .about-top-image {
        width: 90%;
        margin-right: 0;
    }

    .about-us-section > *:nth-child(2) {
        width: inherit;
    }

    .about-ning {
        flex-direction: column;
    }

    .about-ning > * {
        width: 100%;
    }

    .about-ning > img {
        margin-bottom: 35px;
    }

    .about-us-section,
    .our-difference-section {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 60px;
    }

    .our-difference-section {
        flex-direction: column;
    }

    .about-us-section > *:first-child,
    .our-difference-section > *:nth-child(2){
        width: 90%;
        margin-right: 0;
    }

    .our-difference-section > *:first-child {
        width: 90%;
    }

    .about-title {
        margin-bottom: 0;
    }

    .about-us-image {
        width: 90%;
        max-width: unset;
        margin-right: 0;
        margin-bottom: 30px;
    }
}

@media (max-width: 499px) {

    .about-page {
        padding-left: 5px;
        padding-right: 5px;
    }

    .about-body-text {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
    }

    .about-title {
        font-size: 30px;
        line-height: 40px;
    }

    .about-top-section {
        margin-bottom: 30px;
    }

    .about-us-section {
        margin-top: 30px;
    }

    .about-us-image,
    .about-top-image {
        height: 300px;
    }
}


.contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 95%;
    background: #191919;
    color: #bcc5d1;
    padding: 30px 2.5% 15px 2.5%;
    margin-bottom: 30px;
    margin-top: 30px;
}

.contact-headers {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;
    margin-bottom: 50px;
    line-height: 24px;
    align-items: center;
}

.contact-headers > *:first-child {
    width: 25%;
}

/*.contact-headers > *:first-child:before {*/
/*    font-family: 'FontAwesome';*/
/*    content: "\f0e0";*/
/*}*/

.contact-headers > *:nth-child(2) {
    width: 75%;
}

.lower-contact {
    display: flex;
    flex-direction: row;
    width: 100%;
}

.contact-form,
.contact-labels {
    display: flex;
    flex-direction: column;
}

.contact-labels {
    width: 25%;
    color: #EBEEEE;
}

.contact-form {
    width: 70%;
}

.contact-form > * {
    padding-left: 10px;
    color: #eee;
    background: #373233;
    border: 0;
    outline: none;
    font-size: 15px;
}

.contact-form > textarea {
    padding-top: 10px;
    width: auto;
    resize: vertical;
    font: inherit;
}
.contact-labels > *,
.contact-form > * {
    height: 50px;
    margin-bottom: 20px;
}

.email-link {
    color: #11ABB0;
    text-decoration: none;
    transition: color 0.2s ease;
}

.email-link:hover {
    color: white;
}

.contact-labels > * {
    display: flex;
    align-items: center;
    /*justify-content: center;*/
}

.contact-submit {
    width: 90px;
    height: 40px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 3px;
    color: #fff;
    background: #0D0D0D;
    border: none;
    cursor: pointer;
    display: inline-block;
    border-radius: 3px;
    transition: background-color 0.2s ease, color 0.2s ease;
}

.contact-submit:hover {
    color: #0D0D0D;
    background: #fff;
}

:root {
    /*  Add things like global sizes, colors, fonts, etc. as variables and/or declarations here  */
    box-sizing: border-box;
    /* This is a placeholder - can change this to anything we want. */
    --accent-color: #2472AB;
    --secondary-background-color: #C4C4C4;
    --primary-text-color: black;
    font-family: Open Sans, sans-serif;
    --background-blue: #92BCED;
    --min-width: 0;
    --max-width: 1500px;
    --clear-white: rgba(255, 255, 255, 0.54);
}

body {
    margin: 0;
}

.image-container {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
}

.constrained-image {
    width: 100%;
    height: 100%;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.align-center {
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.hidden {
    display: none;
}

