@import "~react-image-gallery/styles/css/image-gallery.css";

.org-page-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100vw;
    max-width: 1500px;
    padding-top: 50px;
    padding-bottom: 10px;
}

.social-icons-container {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.paragraph-container {
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: flex-start;
    width: 100%;
    height: 90%;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 5px;
}

.org-cost-block {
    background-color: var(--background-blue);
    width: 70%;
    font-weight: bold;
    padding: 5px 7%;
}

.info-section-header {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
}

.info-section-subheader {
    margin-bottom: 15px;
    margin-top: 5px;
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 25px;
}

.info-section-subheader:nth-child(2) {
    margin-bottom: 0;
    margin-top: 0;
}

.paragraph-text {
    font-size: 17px;
}

.top-images {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    height: 40%;
    max-height: 490px;
}

.top-image-container,
.org-image-container {
    width: 47.5%;
    height: 100%;
    align-self: center;
}

.org-image-container {
    height: 550px;
}

.org-page-additional-info {
    align-self: center;
    height: auto;
}

.top-image-container > img,
.org-image-container > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
}

.org-image-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    max-height: 550px;
    margin-bottom: 25px;
}

.bottom-paragraph-caption {
    margin-right: 20%;
    align-self: flex-end;
    text-wrap: none;
}

.org-carousel-image {
    display: flex;
    align-items: center;
    width: 100%;
    height: 400px;
}

.org-carousel-image > div > img {
    height: 100%;
    align-self: center;
}

.paragraph-caption {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 50px;
    margin-bottom: 15px;
}

.info-section {
    width: 40%;
    margin-left: 1.25%;
    max-width: 480px;
    font-size: 18px;
    margin-bottom: 25px;
}

.info-block {
    background: var(--secondary-background-color);
    padding: 64px 48px 12px 48px;
}

.info-line {
    margin: 8px 0 20px 0;
}

.who-we-are-section {
    margin-bottom: -250px;
    margin-top: 25px;
    padding: 62px 5% 62px 5%;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    background: var(--background-blue);
}

.who-we-are-section > *:first-child {
    margin-bottom: 20px;
}

.why-your-work-matters-section {
    background: #4878B1;
    margin-top: 50px;
    padding: 140px 8% 85px;
    z-index: -10;
}

.cost-block {
    display: flex;
    flex-direction: column;
    margin-top: 60px;
    margin-bottom: 120px;
}

.org-page-testimonial {
    margin-top: 75px;
    margin-bottom: 75px;
}


.org-page-upper-section {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    margin-bottom: 50px;
    width: 100%;
    height: 50%;
}

.testimonial-div {
    /*margin-bottom: 5%;*/
    text-align: center;
}

.org-page-upper-right {
    display: flex;
    flex-direction: column;
    width: 55%;
    margin-right: 1.25%;
    justify-content: space-evenly;
    align-content: center;
    padding-bottom: 40px;
}

.org-page-box-section {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.always-top-box-section {
    height: 50%;
}

.box {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /*border: solid thin black;*/
    width: 50%;
    margin: 0 5px 0 5px;
}

.box-image {
    max-width: 100%;
    align-self: center;
}

.box-text {

}

.additional-org-info-title {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 37px;
    margin-bottom: 20px;
}

.cost-dropdown {
    width: 60%;
}

.cost-dropdown:hover {
    cursor: pointer;
}

.cost-select {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    flex-direction: row;
    margin-bottom: 15px;
}

.cost-text {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
}

.cost-lower-text {
    margin-top: 5px;
    text-align: center;
    font-size: 16px;
    width: 80%;
    align-self: center;
}

.left-box {
    margin-right: 3%;
}

.right-box {
    margin-left: 3%;
}

.more-info {
    text-align: center;
    height: 60px;
    width: 100%;
    align-self: center;
    background-color: var(--accent-color);
    color: black;
    line-height: 60px;
    text-decoration: none;
    margin-bottom: 20px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: Open Sans, sans-serif;
    font-weight: bold;
}

.socials-div {
    display: flex;
    flex-direction: row;
    align-self: center;
}

.socials-component {
    margin: 0 5px 0 5px;
}

.socials-component:hover {
    cursor: pointer;
    filter: brightness(120%);
}

.more-info:hover {
    filter: brightness(120%);
    cursor: pointer;
}

@media (max-width: 1199px) {

    .org-page-upper-section {
        margin-bottom: 0;
    }

    .who-we-are-section {
        max-width: 75%;
        margin-bottom: -150px;
    }

    .org-page-upper-right {
        width: 50%;
        margin-left: 0;
        padding-bottom: 0;
    }

    .org-page-box-section {
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
    }

    .bottom-box-section {
        width: 100%;
        align-items: center;
        flex-direction: row;
    }

    .box {
        width: 100%;
        margin-top: 3%;
    }

    .left-box {
        margin-right: 0;
    }

    .right-box {
        margin-left: 0;
    }

    .top-images {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-height: none;
    }

    .top-image-container {
        height: 47.5%;
        width: 100%;
        max-height: 450px;
    }

    .top-image-container:first-child {
        margin-bottom: 15px;
    }

}

@media (max-width: 699px) {

    .info-section {
        margin-left: 0;
    }

    .info-block {
        padding: 32px 24px 12px 24px;
    }

    .org-page-container {
        align-items: center;
        flex-direction: column;
    }

    .org-page-upper-section {
        align-items: center;
        flex-direction: column;
    }

    .org-page-upper-right {
        width: 100%;
        align-items: center;
        margin-right: 0;
    }

    .org-page-box-section {
        align-items: center;
        flex-direction: column;
    }

    .info-section {
        width: 75%;
        max-width: none;
    }

    .bottom-paragraph-caption {
        align-self: flex-start;
        margin-right: 0;
    }

    .who-we-are-section {
        margin-bottom: 25px;
    }

    .why-your-work-matters-section {
        margin-bottom: 10px;
    }

    .who-we-are-section {
        max-width: none;
    }

    .why-your-work-matters-section {
        padding-top: 85px;
    }
}

@media (max-width: 499px) {

    .paragraph-text {
        font-size: 14px;
        line-height: 24px;
    }

    .paragraph-caption {
        font-size: 28px;
        margin-bottom: 5px;
    }

    .info-section {
        font-size: 18px;
    }

    .additional-org-info-title {
        font-size: 28px;
    }

    .cost-lower-text {
        font-size: 16px;
    }

}
