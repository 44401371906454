.info-page {
    max-width: 1300px;
    width: 90vw;
}

.info-page-title {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 55px;
    line-height: 81px;
    margin-bottom: 15px;
}

.info-page-text {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 33px;
}

.see-all-locations-block {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
}

.see-all-locations {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 56px;
    margin-bottom: 15px;
}

.info-page-tagline {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 30px;
    line-height: 43px;
    margin-left: 10%;
}

.info-page-short-link-block {
    height: 300px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.info-page-top-section {
    margin-bottom: 30px;
}

.info-page-rotating-block {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 715px;
}

.info-page-rotating-block > * {
    width: 46%;
}

.info-page-rotating-block > a,
.info-page-bottom-link-container > a {
    color: inherit;
}

.info-page-img-container {
    position: relative;
    height: 90%;
    border: solid black;
    max-height: 600px;
}

.info-page-img-container > img {
    text-align: center;
    height: 100%;
    width: 100%;
    object-fit: cover;
    transition: filter 0.2s ease;
}

.info-page-img-container:hover > img {
    filter: brightness(0.7);
}

.info-page-img-container > div {
    position: absolute;
    font-family: Open Sans, sans-serif;
    background-color: var(--clear-white);
    padding: 8px;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 75px;
}

.info-page-bottom-link-container {
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.info-page-bottom-link-container > * {
    height: 46%;
}

.activity-page-bottom-link-container > * {

}

.activity-page-bottom-link-container {
    height: 1000px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.info-page-more-info {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 45px;
    line-height: 56px;
}

.more-info-block {
    max-width: 520px;
    margin-right: 10px;
}

.voluntourism-quote {
    font-family: Overpass , sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 35px;
    line-height: 55px;
    max-width: 600px;
    margin-right: 25px;
}

.image-text {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 70px;
    line-height: 95px;
}

.see-all-img {
    height: 200px;
    width: 60%;
    max-width: 600px;
}

.more-info-text {
    width: 400px;
}

@media (max-width: 850px) {
    .info-page-rotating-block,
    .info-page-short-link-block {
        flex-direction: column;
        height: auto
    }

    .voluntourism-quote {
        margin-right: 0;
    }

    .info-page-short-link-block {
        margin-top: 30px;
    }

    .info-page-rotating-block > *,
    .info-page-short-link-block > * {
        width: 90%;
        margin-bottom: 30px;
    }

    .info-page-bottom-link-container > * {
        width: 100%;
        height: auto;
        justify-content: flex-start;
        margin-bottom: 30px;
    }

    .info-page-bottom-link-container {
        height: auto;
        margin-bottom: 0;
    }

    .info-page-short-link-block {

    }

    .more-info-text {
        width: auto;
    }


    .info-page-img-container {
        height: 300px;
    }

    .see-all-locations-block {
        /*height: 250px;*/
    }

    .info-page-top-section {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 499px) {

    .info-page-img-container {
        height: 200px;
    }

    .info-page-top-section {
        margin-bottom: 30px;
    }

    .info-page-title {
        margin-top: 10px;
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 10px;
    }

    .info-page-text {
        font-size: 14px;
        line-height: 22px;
        margin-top: 5px;
    }

    .info-page-tagline {
        font-size: 20px;
        line-height: 25px;
    }

    .see-all-locations {
        margin: 0;
        font-size: 28px;
    }

    .info-page-img-container > div {
        font-size: 35px;
        line-height: 45px;
    }

    .voluntourism-quote {
        font-size: 25px;
        line-height: 35px;
    }

    .info-page-more-info {
        font-size: 28px;
        line-height: 38px;
        margin-bottom: 0;
    }

    .info-page {
        padding-top: 0;
    }
}

