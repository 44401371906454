

.filter-bar {
    width: 100%;
    background-color: var(--background-blue);
    display: flex;
    flex-direction: column;
    padding: 35px 0 35px 0;
    margin-top: 20px;
    font-family: Open Sans, sans-serif;
}

.filter-bar-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 10px 50px;
}

.filter-bar-row > * {
    margin: 0;
}

.filter-bar-right {
    width: 60%;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    font-size: 20px;
}

.filter-bar-title {
    width: 30%;
    font-size: 25px;
    font-family: Overpass, sans-serif;
    font-weight: bold;
}
.filter-bar-left-label {
    text-align: center;
    width: 60px;
}

.filter-bar-slider {
    width: 70%;
    margin-right: 20px;
    margin-left: 20px;
}

.filter-bar-bottom-text {
    width: 80%;
    align-self: center;
    margin-top: 40px;
}

@media (max-width: 900px) {
    .filter-bar-row {
        flex-direction: column;
        align-items: center;
    }
    .filter-bar-right {
        width: 100%;
    }
    .filter-bar-title {
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
        font-size: 25px;
    }
}

@media (max-width: 499px) {
    .filter-bar-row, .filter-bar-right {
        font-size: 16px;
    }

    .filter-bar-title {
        font-size: 22px;
    }
}
