.howto-page {
    max-width: 1200px;
    padding-top: 80px;
    width: 100%;
}

.howto-top-container {
    display: flex;
    flex-direction: row;
    min-height: 640px;
    margin-right: 3%;
    margin-left: 3%;
}

.howto-top-text {
    justify-content: center;
}

.howto-top-image {
    max-height: 640px;
    width: 50%;
    margin-right: 70px;
    object-fit: cover;
}

.howto-bottom-image {
    max-height: 480px;
    width: 50%;
    margin-right: 70px;
    object-fit: cover;
}

.howto-info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.howto-title {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 40px;
    line-height: 62px;
    margin-bottom: 20px;
}

.howto-body-text {
    font-family: Open Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 31px;
}

.howto-lines {
    margin-left: 5%;
    margin-right: 5%;
}

.read-more-button {
    height: 80px;
    width: 250px;
    text-align: center;
}

.howto-button {
    font-family: Roboto, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 34px;
    align-self: center;
    /*border: 1px solid lightslategray;*/
    border: none;
    transition: ease filter 0.2s;
    padding-left: 10px;
    text-wrap: none;
    background-color: var(--background-blue);
}

.howto-button:hover {
    filter: brightness(0.85);
}

.howto-button:hover {
    cursor: pointer;
}

.read-more-button:active {
    text-decoration: underline;
}

.howto-line-text {
    margin-right: 50px;
    width: 70%;
}


.howto-bottom-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 30px 3% 20px;
}

.howto-bottom-info {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-content: center;
    margin-right: 15px;
}

.howto-bottom-text {
    margin-bottom: 50px;
}

.download-checklist {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-decoration: inherit;
    color: inherit;
    height: 160px;
    width: 100%;
    max-width: 560px;
    text-align: center;
}

@media screen and (max-width: 999px) {
    .howto-bottom-container {
        flex-direction: column;
    }

    .howto-bottom-image {
        width: 80%;
        margin-right: 0;
    }

    .howto-bottom-info {
        width: 80%;
    }

    .howto-bottom-text {
        margin-bottom: 20px;
    }
}

@media screen and (max-width: 700px) {
    .howto-top-container {
        flex-direction: column-reverse;
        align-items: center;
    }

    .howto-top-text {
        align-items: center;
        margin-bottom: 50px;
    }

    .howto-top-image {
        margin: 0;
        width: 90%;
    }

    .howto-bottom-image {
        margin-right: 0;
    }

    .howto-bottom-container {
        flex-direction: column;
        align-items: center;
    }

    .howto-bottom-info {
        width: 80%;
    }

    .howto-lines {
        align-items: center;
        margin-bottom: 10px;
    }

    .howto-title {
        margin-bottom: 10px;
        text-align: center;
    }

    .howto-info {
        flex-direction: column;
        align-items: center;
    }

    .howto-info > *:first-child {
        margin-bottom: 25px;
        margin-right: 0;
        width: 100%;
    }
}

@media (max-width: 499px) {

    .howto-page {
        padding-top: 0;
    }

    .howto-top-container {
        margin-bottom: 20px;
    }

    .howto-top-text {
        margin-bottom: 30px;
    }

    .download-checklist {
        height: 70px;
    }

    .howto-title {
        font-size: 28px;
        line-height: 36px;
    }

    .howto-body-text {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 10px;
    }

    .howto-info > *:first-child {
        margin-bottom: 10px;
        margin-right: 0;
        width: 100%;
    }

    .read-more-button {
        text-align: center;
        padding: 0;
    }

    .howto-button {
        width: 150px;
        height: 50px;
        font-size: 16px;
    }
}
