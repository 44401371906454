.see-all-programs-div {
    margin-top: 40px;
    height: 150px;
}

.activity-page-bottom-link-container > * {
    color: inherit;
}

.medical-box {
    max-height: 600px;
}

@media screen and (max-width: 850px) {
    .see-all-programs-div {
        margin-top: 30px;
        margin-bottom: 50px;
    }

    .activity-page-bottom-link-container {
        height: auto;
    }
}

@media screen and (max-width: 499px) {
    .see-all-programs-div {
        margin-bottom: 30px;
    }

    .activity-page-bottom-link-container {
        margin-bottom: 0;
    }
}
