label {
    display: block;
    font: .9rem 'Fira Sans', sans-serif;
}

input[type='submit'],
label {
    margin-top: 1rem;
}

.login-page {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-content: center;
}

.admin-information-page {
    padding-top: 100px;
}

.admin-errors-row {
    display: flex;
    margin: 10px;
    justify-content: space-between;
}

.admin-errors {
    margin: 20px;
    border: solid black;
}
