@import url('https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Open+Sans:wght@300;400;700&family=Overpass:wght@100;300;400;700&family=Roboto:wght@700&display=swap');
:root {
    /*  Add things like global sizes, colors, fonts, etc. as variables and/or declarations here  */
    box-sizing: border-box;
    /* This is a placeholder - can change this to anything we want. */
    --accent-color: #2472AB;
    --secondary-background-color: #C4C4C4;
    --primary-text-color: black;
    font-family: Open Sans, sans-serif;
    --background-blue: #92BCED;
    --min-width: 0;
    --max-width: 1500px;
    --clear-white: rgba(255, 255, 255, 0.54);
}

body {
    margin: 0;
}

.image-container {
    width: 100%;
    height: 100%;
    position: relative;
    text-align: center;
}

.constrained-image {
    width: 100%;
    height: 100%;
}

.flex-column {
    display: flex;
    flex-direction: column;
}

.flex-row {
    display: flex;
    flex-direction: row;
}

.align-center {
    align-items: center;
}

.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
}

.hidden {
    display: none;
}
