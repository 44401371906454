.burger-container {
    display: inline-block;
    cursor: pointer;
}

.burger-bar1, .burger-bar2, .burger-bar3 {
    width: 35px;
    height: 5px;
    background-color: #333;
    margin: 6px 0;
    transition: 0.4s;
}

/* Rotate first bar */
.burger-change .burger-bar1 {
    -webkit-transform: rotate(-45deg) translate(-9px, 6px) ;
    transform: rotate(-45deg) translate(-9px, 6px) ;
}

/* Fade out the second bar */
.burger-change .burger-bar2 {
    opacity: 0;
}

/* Rotate last bar */
.burger-change .burger-bar3 {
    -webkit-transform: rotate(45deg) translate(-8px, -8px) ;
    transform: rotate(45deg) translate(-8px, -8px) ;
}

.menu-container {
    position: fixed;
    left: 0;
    top: 0;
    width: auto;
    display: flex;
    flex-direction: column;
    padding: 20px 20px 50px 20px;
}

.menu-background {
    /*background-color: #6e6e6e;*/
    background-color: var(--background-blue);
}

.menu-link:hover {
    /*text-decoration: underline;*/
}