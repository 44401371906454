.about-page {
    padding-top: 80px;
    padding-left: 5%;
    padding-right: 5%;
    align-items: center;
    max-width: 1200px;
    width: 90vw;
}

.about-top-section {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 80px;
}

.about-top-image {
    object-fit: cover;
    width: 60%;
    margin-right: 100px;
    height: 600px;
}

.about-mid-text {
    margin-bottom: 50px;
}

.about-us-section,
.our-difference-section {
    margin-top: 100px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.our-difference-section {
    margin-bottom: 50px;
}

.about-us-section > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    width: 40%;
}

.our-difference-section > *:first-child {
    width: 45%;
    margin-right: 50px;
}

.about-us-section > *:first-child,
.our-difference-section > *:nth-child(2) {
    display: flex;
    flex-direction: column;
    width: 40%;
    justify-content: flex-start;
    margin-right: 50px;
}

.about-us-image {
    object-fit: cover;
    width: 50%;
    height: 550px;
}

.about-body-text {
    font-family: Open Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 25px;
    line-height: 34px;
}

.about-title, .about-subtitle {
    font-family: Overpass, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 50px;
    line-height: 77px;

    margin-bottom: 30px;
}

.about-subtitle {
    margin-bottom: 10px;
}

.about-ning {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 50px;
}

.about-ning > * {
    width: 45%;
    height: auto
}

@media (max-width: 1099px) {
    .about-body-text {
        font-size: 20px;
        line-height: 28px;
    }

    .about-title {
        font-size: 40px;
        line-height: 55px;
    }
}

@media (max-width: 999px) {
    .about-top-image {
        width: 50%;
    }
}

@media (max-width: 699px) {
    .about-top-section {
        flex-direction: column-reverse;
        align-items: center;
        margin-bottom: 50px;
    }

    .about-top-image {
        width: 90%;
        margin-right: 0;
    }

    .about-us-section > *:nth-child(2) {
        width: inherit;
    }

    .about-ning {
        flex-direction: column;
    }

    .about-ning > * {
        width: 100%;
    }

    .about-ning > img {
        margin-bottom: 35px;
    }

    .about-us-section,
    .our-difference-section {
        flex-direction: column-reverse;
        align-items: center;
        margin-top: 60px;
    }

    .our-difference-section {
        flex-direction: column;
    }

    .about-us-section > *:first-child,
    .our-difference-section > *:nth-child(2){
        width: 90%;
        margin-right: 0;
    }

    .our-difference-section > *:first-child {
        width: 90%;
    }

    .about-title {
        margin-bottom: 0;
    }

    .about-us-image {
        width: 90%;
        max-width: unset;
        margin-right: 0;
        margin-bottom: 30px;
    }
}

@media (max-width: 499px) {

    .about-page {
        padding-left: 5px;
        padding-right: 5px;
    }

    .about-body-text {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
    }

    .about-title {
        font-size: 30px;
        line-height: 40px;
    }

    .about-top-section {
        margin-bottom: 30px;
    }

    .about-us-section {
        margin-top: 30px;
    }

    .about-us-image,
    .about-top-image {
        height: 300px;
    }
}
