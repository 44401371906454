.search-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 20px;
    min-width: 411px;
}

.search-header {
    width: 90%;
}

.search-title {
    font-size: 55px;
}

.bottom-search-section {
    display: flex;
    flex-direction: row;
    margin-top: 20px;
    width: 100%;
}

.bold {
    font-weight: bold;
}

.search-page-search-bar {
    width: 50%;
    margin-top: 20px;
}

.search-page-selector {
    margin-top: 20px;
    background-color: var(--secondary-background-color);
    width: 20%;
    /*padding: 60px 0 0 0;*/
    display: flex;
    flex-direction: column;
    max-width: 280px;
    height: 100%;
}

.search-selector-separator {
    width: 85%;
    /*border: 1px solid var(--primary-text-color);*/
}

.search-page-selector > * {
    margin: 0 15px 0 15px;
}

.selector-option {
    margin: 20px;
    font-size: 16px;
    text-color: var(--primary-text-color);
}

.selector-option:hover {
    color: var(--accent-color);
    cursor: pointer;
}

.other-filters {
    display: flex;
    flex-wrap: wrap;
    margin: 0 0 30px 0;
    width: 100%;
    /*background-color: var(--accent-color);*/
}

.other-filters-option {
    width: 80%;
    color: var(--primary-text-color);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 4px 20px;
}

.other-filters-option:first-child {
    margin-top: 8px;
}

@media (max-width: 950px) {
    .bottom-search-section {
        flex-direction: column;
    }

    .other-filters-option:first-child {
        margin-top: 4px;
    }

    .search-page-selector {
        flex-direction: row;
        justify-content: space-evenly;
        width: auto;
        height: auto;
        padding: 0;
        max-width: none;
    }

    .other-filters {
        margin: 0;
        height: 200px;
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        align-content: center;
        height: auto;
        width: 570px;
    }

    .other-filters-option {
        width: 45%;
        margin-left: 10px;
        margin-right: 10px;
    }

    .search-page-options {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .search-selector-separator {
        width: auto;
    }
}

@media screen and (max-width: 630px) {

    .search-title {
        font-size: 45px;
    }

    .search-page-selector {
        flex-direction: column;
        align-items: center;
    }

    .search-selector-separator {
        width: 100%;
    }

    .search-page-options {
        flex-direction: row;
        flex-wrap: wrap;
    }

    .selector-option {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: auto;
    }

    .other-filters {
        width: auto;
    }
}

@media (max-width: 499px) {
    .other-filters-option {
        margin-left: 9px;
        margin-right: 9px;
    }

    .search-page {
        margin-left: 25px;
        padding-top: 20px;
    }

    .search-page-search-bar {
        width: 80%;
        margin-top: 40px;
    }
}
